<template>
  <div class="my-register-page">
    <div class="my-container">
      <b-row>
        <b-col class="mt-4" style="text-align: right">
          <b-button
            style="width: 100px"
            @click="$router.push({ name: 'Cabinet' })"
            variant="outline-danger"
          >
            {{ $t("back") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2 mt-2 pt-1">
        <b-col sm="12" lg="10" class="text-center">
          <h1>{{ $t("inspectionbookAll") }}</h1>
        </b-col>
        <b-col sm="12" lg="2" style="text-align: right">
          <b-button
            class="mt-1"
            style="width: 120px"
            @click="Create"
            variant="primary"
            ><b-icon icon="plus"></b-icon> {{ $t("Create") }}
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple bordered>
        <b-thead class="text-center">
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              {{ $t("orderCode") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("docNumber") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("checkDate") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("orderedOrganization") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("inspectionOrganization") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("actions") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>
              <b-form-input
                @keyup.enter="Refresh"
                v-model="filter.filters.orderNumber.value"
                :placeholder="$t('orderCode')"
              ></b-form-input>
            </b-td>
            <b-td>
              <b-form-input
                @keyup.enter="Refresh"
                v-model="filter.filters.docNumber.value"
                :placeholder="$t('docNumber')"
              ></b-form-input>
            </b-td>
            <b-td> </b-td>
            <b-td>
              <b-form-input
                @keyup.enter="Refresh"
                v-model="filter.filters.orderedOrganization.value"
                :placeholder="$t('orderedOrganization')"
              ></b-form-input>
            </b-td>
            <b-td>
              <b-form-input
                @keyup.enter="Refresh"
                v-model="filter.filters.inspectionOrganizationName.value"
                :placeholder="$t('inspectionOrganization')"
              ></b-form-input>
            </b-td>
          </b-tr>
          <b-tr v-for="(item, index) in Requests" :key="index">
            <b-td class="text-nowrap" style="vertical-align: middle">
              {{ item.orderCode }}
            </b-td>
            <b-td class="text-nowrap" style="vertical-align: middle">
              {{ item.docNumber }}
            </b-td>
            <b-td style="white-space: nowrap">
              {{ item.startDate }} - {{ item.endDate }} ({{
                item.checkDaysNumber
              }}
              {{ $t("day") }} )
            </b-td>
            <b-td>
              {{ item.orderedOrganization }}
            </b-td>
            <b-td>
              {{ item.inspectionOrganizationName }}
            </b-td>
            <b-td style="vertical-align: middle; text-align: center">
              <b-icon
                v-b-tooltip.hover
                :title="$t('View')"
                style="cursor: pointer; color: #0d6efd"
                @click="View(item)"
                icon="eye"
              ></b-icon>
              <b-dropdown
                v-b-tooltip.hover
                :title="$t('edit')"
                id="dropdown-1"
                variant="link"
                class="m-md-2"
              >
                <template #button-content>
                  &#x1F589;<span class="sr-only"></span>
                </template>

                <b-dropdown-item @click="EditInspector(item)">{{
                  $t("editInspector")
                }}</b-dropdown-item>
                <b-dropdown-item @click="EditContractor(item)">
                  {{ $t("editContractor") }}</b-dropdown-item
                >
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="filter.totalRows"
          :per-page="filter.pageSize"
          first-number
          last-number
          @input="Refresh"
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <b-icon icon="chevron-double-left" size="18" />
          </template>
          <template #next-text>
            <b-icon icon="chevron-double-right" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      :title="$t('Add')"
      no-close-on-backdrop
      centered
      hide-footer
      v-model="OpenModal"
      size="md"
    >
      <b-row>
        <b-col sm="12" md="6">
          <b-button
            class="mt-1 ml-1"
            style="width: 200px"
            @click="createInspector"
            variant="primary"
            ><b-icon icon="plus"></b-icon> {{ $t("createInspector") }}
          </b-button>
        </b-col>
        <b-col sm="12" md="6" class="text-right ml-3">
          <b-button
            class="mt-1 ml-3 text-right"
            style="width: 200px"
            @click="createContractor"
            variant="primary"
            ><b-icon icon="plus"></b-icon> {{ $t("createContractor") }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import InspectionBookService from "@/services/inspectionbook.service";
import InspectionBookOfContractorService from "@/services/inspectionbookofcontractor.service";
import customSelect from "../../components/elements/customSelect.vue";
import customInput from "../../components/elements/customInput.vue";
import InspectionBook from "../inspectionbook/index.vue";
import customButton from "../../components/elements/customButton.vue";
import customDatePicker from "../../components/customDatePickerWhite.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconEyeFill,
  BPagination,
  BModal,
  BCollapse,
  BFormInput,
} from "bootstrap-vue";
export default {
  data() {
    return {
      Requests: [],
      modal: false,
      isSave: false,
      OpenModal: false,
      SearchLoading: false,
      visible: false,
      filter: {
        search: "",
        page: 1,
        pageSize: 20,
        totalRows: 1,
        options: [
          {
            value: 10,
            text: "10",
          },
          {
            value: 20,
            text: "20",
          },
          {
            value: 50,
            text: "50",
          },
          {
            value: 100,
            text: "100",
          },
        ],
        filters: {
          docNumber: {
            value: "",
            matchmode: "contains",
          },
          orderNumber: {
            value: "",
            matchmode: "contains",
          },
          inspectionOrganizationName: {
            value: "",
            matchmode: "contains",
          },
          orderedOrganization: {
            value: "",
            matchmode: "contains",
          },
        },
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTableSimple,
    BIconEyeFill,
    BPagination,
    customSelect,
    InspectionBook,
    BModal,
    customInput,
    customButton,
    BCollapse,
    BFormInput,
    customDatePicker,
  },
  created() {
    this.Refresh();
  },
  methods: {
    Create() {
      this.OpenModal = true;
    },
    EditInspector(item) {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: item.id },
        query: { contractor: false },
      });
    },
    EditContractor(item) {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: item.id },
        query: { contractor: true },
      });
    },
    createContractor() {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: 0 },
        query: { contractor: true },
      });
    },
    createInspector() {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: 0 },
        query: { contractor: false },
      });
    },
    Refresh() {
      InspectionBookService.GetList(this.filter)
        .then((res) => {
          this.Requests = res.data.rows;
          this.filter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    View(item) {
      this.$router.push({
        name: "InspectionBookOfContractorView",
        params: { id: item.id },
        query: {
          inspectionBookId: item.inspectionBookId,
          inspectionBookOfContractorId: item.inspectionBookOfContractorId,
        },
      });
    },
    GetIBContractor(item) {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: item.id },
      });
    },
    GetIB(item) {
      this.$router.push({
        name: "InspectionBookEdit",
        params: { id: item.id },
        query: { fromRequest: true },
      });
    },
    GetComplaint(item) {
      this.$router.push({ name: "EditComplaint", params: { id: item.id } });
    },
    BackModal() {
      this.modal = false;
    },
    Send() {
      this.modal = true;
    },
    SendFunction(item) {
      this.isSave = true;
      InspectionBookOfContractorService.Send({ id: item.id })
        .then((res) => {
          this.makeToast(this.$t("SuccessSend"), "success");
          this.modal = false;
          this.Refresh();
          this.isSave = false;
        })
        .catch((error) => {
          this.makeToast(error.response.data.errors, "error");
          this.modal = false;
        });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    Get(item) {
      this.$router.push({
        name: "InspectionBookOfContractorEdit",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style>
.nav-link {
  color: #293958 !important;
}
</style>
